export const getters = {
  getLinkedDevices(state) {
    return state.linkedDevices;
  },
  getUnlinkedDevices(state) {
    return state.unlinkedDevices;
  },
  getCurrentTestingDevice(state) {
    return state.currentTestingDevice;
  },
  getCurrentProperty(state) {
    return state.currentProperty;
  }
};