export const mutations = {
  getAllFlats(state, payload) {
    if(state.currentProperty._id === payload[0].property) {
      state.flats = [];
    }
    for(const payloadFlat of payload) {
      if(payloadFlat.property === state.currentProperty._id) {
        if(!payloadFlat.device) {
          state.flats.push(payloadFlat);
        }
      }
    }
  },
  setProperties(state, payload) {
    state.properties = payload;
  },
  setCurrentProperty(state, payload) {
    state.currentProperty = payload;
  },
};
  