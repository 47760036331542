import Vuex from "vuex";

import login from "./modules/login";
import { device } from "./modules/device/index";
import { flat } from "./modules/flat/index";

export default new Vuex.Store({
  modules: {
    login: login,
    device,
    flat
  }
});
