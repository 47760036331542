<template>
  <div class="body-container">
    <header>
      <img src="/images/logo_admin.png" alt="logo" class="logo-image">
      <div class="navbar">
        <div class="navbar-links">
          <router-link class="navbar-link" to="/home">
            <img src="/images/home-icon.png" alt="home image" class="navbar-home-image">
            <p>Home</p>
          </router-link>
        </div>
      </div>
      <Multiselect
        v-model="propertySelector.value"
        :options="propertySelector.options"
        @select="filterByProperty()"
        class="multiselect"
      >
        <template v-slot:singlelabel="{ value }">
          <div class="multiselect-single-label property">
            <img class="property-icon" :src="value.icon">
            <span class="property-name">{{ value.name }}</span>
          </div>
        </template>

        <template v-slot:option="{ option }">
          <div class="property">
            <img class="property-icon" :src="option.icon">
            <span class="property-name">{{ option.name }}</span>
          </div>
        </template>
      </Multiselect>
      <router-link to="/">
        <img src="/images/logout.png"  alt="logout icon" class="logout-image" @click="logout">
      </router-link>
    </header>
    <main>
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'Admin',
  components: {
    Multiselect
  },
  sockets: {
    update: function(properties) {
      switch (properties.type) {
        case 'properties':
          this.setProperties(properties.data);
          this.propertySelector.options = properties.data.map(property => {
            return {...property, icon: '/images/property-icon.png', value: property._id};
          });
          if(!this.propertySelector.value) {
            if(localStorage.getItem('property')) {
              this.propertySelector.value = localStorage.getItem('property')
            } else {
              this.propertySelector.value = properties.data[0]._id;
            }
            this.filterByProperty();
          }
          break;
      }
    },
  },
  data() {
    return {
      selectHome: true,
      propertySelector: {
        mode: 'single',
        value: null,
        options: []
      },
    }
  },
  methods: {
    ...mapActions(['logOut', 'setPropertyConfiguration']),
    ...mapMutations(['setCurrentProperty', 'setProperties', 'setCurrentTestingDevice']),
    logout() {
      this.logOut();
      localStorage.removeItem('property');
    },
    filterByProperty() {
      const properties = this.getProperties;
      const currentProperty = properties.find(property => property._id === this.propertySelector.value);
      this.setCurrentProperty(currentProperty);
      this.setCurrentTestingDevice({})
      localStorage.setItem('property', this.propertySelector.value);
      this.$socket.emit('flats', {
        token: localStorage.getItem('token'),
        property: this.propertySelector.value
      });
      this.$socket.emit('devices', {
        token: localStorage.getItem('token')
      });
    }
  },
  computed: {
    ...mapGetters(['getLoading', 'getProperties', 'getCurrentProperty'])
  }
};
</script>

<style lang="scss">
.body-container {
  font-family: "Roboto", sans-serif;

  .loading {
    cursor: wait;
  }

  .loading {
    pointer-events: none; 
  }

  header {
    position: fixed;
    padding: 10px 25px 10px 25px;
    top: 0;
    width: calc(100% - 50px);
    height: 60px;
    background: #022259;
    z-index: 9;
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: space-between;

    .logo-image {
      margin-right: 60px;
      height: 60px;
      width: 130px;
      border-radius: 5px;
    }

    .navbar {
      .navbar-links {
        height: 47px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .navbar-link {
          margin-right: 60px;
          text-decoration: none;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          align-self: center;

          &.active {
            p {
              text-shadow: 0 0 10px #FFFFFF;
            }
          }

          .navbar-home-image {
            width: 25px;
            height: 30px;
            cursor: pointer;
          }

          .navbar-test-image {
            margin-left: 10px;
            width: 36px;
            height:30px;
            cursor: pointer;
          }

          .navbar-monitoring-image {
            width: 45px;
            height:30px;
            cursor: pointer;
          }

          .navbar-configuration-image {
            width: 30px;
            height:30px;
            cursor: pointer;
          }

          p {
            font-size: 14px;
            color: #FFFFFF;
            cursor: pointer;
          }
        }
      }
    }

    .multiselect {
      margin-right: 35px;
      font-size: 14px;
      color: #022259;
      background: #FFFFFF;
      border: none;
      border-radius: 13px;
      cursor: pointer;
      width: 40%;
      height: 40px;
      // min-height: 50px;
      z-index: 10;
    }

    .multiselect.is-active {
      box-shadow: none;
      transition: all 0.5s;
    }

    .multiselect-caret{
      color: #022259;
      background: #FFFFFF;
      background-color: none !important;
      -webkit-mask-image: none;
      width: 20px;
      height: 20px;
      background: url('/images/show-filter.png') no-repeat 50% 50%;
      background-size: contain;
    }

    .multiselect-placeholder, .multiselect-single-label {
      color: #022259 !important;
      font-weight: bold;
    }

    .multiselect-clear {
      display: none;
    }

    .multiselect-option.is-selected {
      background: #cceafd;
    }

    .multiselect-dropdown {
      padding-top: 15px;
      transform: translateY(94%);
      width: 100%;
      left: 0;
      right: 0;
      z-index: -1;
      background-color: #F5F8F8;
      border: none;
      border-bottom-left-radius: 13px;
      border-bottom-right-radius: 13px;
    }

    .property {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .property-icon {
      width: 20px;
      height: 20px;
    }

    .property-name {
      margin-left: 15px;
      color: #000;
      font-size: 14px;
    }

    .logout-image {
      margin-left: auto;
      width: 35px;
      height: 35px;
    }
  }
}

@media screen and (max-width: 1260px) {
  .multiselect {
    width: 30% !important;
  }
}


@media screen and (min-width: 1920px) {
  .multiselect {
    font-size: 18px !important;
    height: 50px !important;
  }

  .property-name {
    font-size: 18px !important;
  }

}

@media screen and (min-width: 2560px) {

  p {
    font-size: 18px !important;
  }

  header {
    padding: 15px 40px 15px 40px !important;
    width: calc(100% - 80px) !important;
    height: 75px !important;

    .logo-image {
      width: 165px !important;
      height: 75px !important;
      margin-right: 130px !important;
    }

    .navbar {
      .navbar-links {
        height: 70px !important;
      }
    }
  }

  .navbar-link {
    margin-top: 6px;
    margin-right: 130px !important;
    p {
      height: 22px !important;
      text-align: center;
    }
  }

  .logout-image {
    width: 45px !important;
    height: 45px !important;
  }

  .navbar-home-image {
    width: 37px !important;
    height: 43px !important;
  }

  .navbar-test-image {
    width: 48px !important;
    height: 43px !important;
  }

  .navbar-monitoring-image {
    width: 58px !important;
    height: 43px !important;
  }

  .navbar-configuration-image {
    width: 43px !important;
    height: 43px  !important;
  }

  .multiselect-caret{
    width: 30px !important;
    height: 30px !important;
  }

  .property-icon {
    width: 30px !important;
    height: 30px !important;
  }

  .multiselect {
    margin-right: 45px !important;
    font-size: 24px !important;
    height: 60px !important;
  }

  .property-name {
    font-size: 24px !important;
  }
}

@media screen and (min-width: 3840px) {

  p {
    font-size: 24px !important;
  }

  header {
    padding: 35px 50px 35px 50px !important;
    width: calc(100% - 100px) !important;
    height: 105px !important;

    .logo-image {
      width: 205px !important;
      height: 105px !important;
      margin-right: 130px !important;
    }

    .navbar {
      .navbar-links {
        height: 105px !important;
      }
    }
  }

  .logout-image {
    width: 65px !important;
    height: 65px !important;
  }

  .navbar-links {
    width: 650px !important;
    height: 105px !important;

    .navbar-link {
      margin-bottom: 5px;
      margin-right: 130px !important;
      .navbar-home-image {
        width: 62px !important;
        height: 70px !important;
      }

      .navbar-test-image {
        margin-left: 10px;
        width: 75px !important;
        height: 70px !important;
      }

      .navbar-monitoring-image {
        width: 90px !important;
        height: 70px !important;
      }

      .navbar-configuration-image {
        width: 70px !important;
        height: 70px !important;
      }
    }
  }

  .multiselect-dropdown {
    transform: translateY(92%) !important;
    height: 400px !important;
  }

  .multiselect-caret{
    width: 40px !important;
    height: 40px !important;
  }

  .property-icon {
    width: 40px !important;
    height: 40px !important;
  }

  .multiselect {
    margin-right: 55px !important;
    font-size: 36px !important;
    height: 90px !important;
  }

  .property-name {
    font-size: 36px !important;
  }
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
