<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
</style>

<template>
  <router-view/>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  sockets: {
    connect: function() {
      console.log('socket connected');
    },
    update: function (updateType) {
      switch (updateType.type) {
        case 'property':
          this.updateCurrentProperty(updateType.data);
          break;
        case 'devices':
          this.groupingDevices(updateType.data);
          break;
        case 'flats':
          this.getAllFlats(updateType.data);
          break;
        case 'device':
          this.updateDevice(updateType.data);
          if(this.getCurrentTestingDevice._id === updateType.data._id) {
            this.setCurrentTestingDevice(updateType.data)
          }
          break;
      }
    },
    pong: function(ping) {
      if(ping.status === false) {
        this.$socket.emit('ping', {
          token: localStorage.getItem('token')
        })
      }
    }
  },
  computed: {
    ...mapGetters(['getCurrentTestingDevice', 'getCurrentProperty'])
  },
  methods: {
    ...mapMutations(['groupingDevices', 'getAllFlats', 'updateDevice', 'setCurrentProperty', 'setCurrentTestingDevice', 'updateCurrentProperty'])
  },
  created () {
    this.$store.dispatch('tryAutoLogin')
    if(localStorage.getItem('token')) {
      const ping = setInterval(() => {
        this.$socket.emit('ping', {
          token: localStorage.getItem('token')
        })
      }, 10000);
      this.$socket.emit('devices', {
        token: localStorage.getItem('token')
      });
      this.$socket.emit('properties', {
        token: localStorage.getItem('token')
      });
    }
  }
}
</script>
<style scoped>
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  margin-right: 5px;
  background-color: #022259;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #003797;
}
</style>
