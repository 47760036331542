import { createApp } from 'vue';
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import VueSocketIO from 'vue-3-socket.io';
import io from 'socket.io-client';
import './assets/css/reset.css';

const socketio = new VueSocketIO({
    debug: true,
    connection: io('https://api.dev.back.nassauische.one.paul-cloud.de', { reconnection: true , transports: ['polling']}),
    vuex: {
        store,
        actionPrefix: 'SOCKET_',
        mutationPrefix: 'SOCKET_'
    }
})

const url = "https://api.dev.back.nassauische.one.paul-cloud.de";

axios.defaults.baseURL = url;
axios.defaults.headers.common['Accept'] = "application/json";
axios.defaults.headers.common['Content-Type'] = "application/json";
axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";

createApp(App).use(store).use(router).use(socketio).mount('#app')
