import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

export const state = {
  unlinkedDevices: [],
  linkedDevices: [],
  currentTestingDevice: {},
  currentProperty: {},
};

export const device = {
  state,
  getters,
  actions,
  mutations
};
