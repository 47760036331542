import axios from 'axios';

const state = {
    token: null
};

const mutations = {
    authUser(state, userData) {
        state.token = userData.token
    },
    clearAuthData(state) {
        state.token = null;
    }
};

const actions = {
    signIn({commit, dispatch}, authData) {
        return new Promise((resolve, reject) => {
            axios.post("/admins/login", authData)
                .then(res => {
                    commit("authUser", {
                        token: res.data.token
                    });
                    localStorage.setItem("token", res.data.token);
                    resolve(res)
                })
                .catch(error => reject(error))
        })
    },
    tryAutoLogin ({commit}) {
        const token = localStorage.getItem("token");

        if (!token) {
            return
        }
        commit("authUser", {
            token: token,
        })
    },
    logOut({commit}) {
        localStorage.removeItem("token");
        commit("clearAuthData");
    }
};

const getters = {
    isAuthenticated(state) {
        return state.token !== null
    },
    getToken(state) {
        return state.token
    }
};

export default {
    state,
    mutations,
    actions,
    getters
}
